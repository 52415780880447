import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  envString = environment.production ? 'Production' : 'Staging';
  title = `Lido Admin Portal for ${this.envString}`;
}
