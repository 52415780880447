import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent {
  displayName = sessionStorage.getItem('displayName');
  items = [
    {
      label: 'Profile',
      icon: 'pi pi-pencil',
      routerLink: '/profile',
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        this.logout();
      },
    },
  ];

  constructor(private authService: AuthService) {}

  logout() {
    sessionStorage.clear();
    this.authService.logout();
  }
}
