import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Validators,
  FormGroup,
  FormBuilder,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { UserService } from '../users/service/user.service';

import { User } from '../users/model/user.model';
import { SecurityQuestionDTO } from '../users/model/securityquestionDto.model';
import { mergeMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { RequestErrors } from '../common/utils';
import { ApiResponse } from '../common/apiresponse';
import { EncrDecrService } from '../common/encr-decr.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  showChangePassword = false;
  msgs: Message[] = [];
  displayFirstPage = true;

  apires: ApiResponse;
  user: User;
  isSignupVerified = false;

  public securityQuestions: SecurityQuestionDTO[];
  loadingSub = false;
  btnCreate: string;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private encrDecrService: EncrDecrService
  ) {}

  switchPage() {
    this.displayFirstPage = !this.displayFirstPage;
  }

  cancelProfile() {
    this.router.navigate(['/home']);
  }

  uniqueQuestions(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const question1 = group.controls['securityQuestionId1'];
      const question2 = group.controls['securityQuestionId2'];
      const question3 = group.controls['securityQuestionId3'];
      if (
        question1.value.securityQuestionId ===
          question2.value.securityQuestionId ||
        question2.value.securityQuestionId ===
          question3.value.securityQuestionId ||
        question3.value.securityQuestionId ===
          question1.value.securityQuestionId
      ) {
        group.setErrors({ notEquivalent: true });
      }
      return null;
    };
  }

  onSubmit() {
    this.loadingSub = true;
    this.btnCreate = 'Processing..';
    this.user.shortName = this.profileForm.controls['shortName'].value;
    this.user.displayName = this.profileForm.controls['displayName'].value;
    sessionStorage.setItem('displayName', this.user.displayName);
    this.user.phoneNumber = this.profileForm.controls['phoneNumber'].value;
    this.user.email = this.profileForm.controls['email'].value;
    this.user.securityQuestionId1 =
      this.profileForm.controls['securityQuestionId1'].value.securityQuestionId;
    this.user.answer1 = this.profileForm.controls['answer1'].value;
    this.user.securityQuestionId2 =
      this.profileForm.controls['securityQuestionId2'].value.securityQuestionId;
    this.user.answer2 = this.profileForm.controls['answer2'].value;
    this.user.securityQuestionId3 =
      this.profileForm.controls['securityQuestionId3'].value.securityQuestionId;
    this.user.answer3 = this.profileForm.controls['answer3'].value;
    this.user.signatureOption = parseInt(
      this.profileForm.controls['signatureOption'].value
    );
    this.user.isProfileVerified = true;
    this.user.isSignatureAccept = true;
    this.saveUser(true);
  }

  saveUser(routeAfterSave = false) {
    this.userService.editUser(this.user.userId, this.user).subscribe(
      (messageResponse) => {
        this.apires = messageResponse;
        this.loadingSub = false;
        this.btnCreate = 'Submit';
        if (this.apires.success === true) {
          this.msgs = [];
          this.msgs.push({ severity: 'success', detail: this.apires.message });

          if (routeAfterSave) {
            this.cancelProfile();
          }
        }

        if (this.apires.success === false) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            detail: this.apires.errorMessages[0],
          });
        }
      },
      (error) => {
        this.loadingSub = false;
        this.btnCreate = 'Submit';
        this.msgs = [];
        let errors: string[] = [];
        errors = RequestErrors(error);
        for (const msg of errors) {
          this.msgs.push({ severity: 'warn', detail: msg });
        }
      }
    );
  }

  ngOnInit() {
    this.initForm();
    this.btnCreate = 'Submit';
    this.isSignupVerified = this.encrDecrService.decryptData(
      sessionStorage.getItem('sgv')
    );
    this.getData();
    if (!this.isSignupVerified) {
      this.changePassword();
    }
  }

  initForm() {
    this.profileForm = this.formBuilder.group({
      shortName: [
        '',
        [
          Validators.required,
          Validators.pattern('[ a-zA-Z]*'),
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      displayName: [
        '',
        [
          Validators.required,
          Validators.pattern('[ a-zA-Z]*'),
          Validators.minLength(3),
          Validators.maxLength(70),
        ],
      ],
      phoneNumber: [''],
      email: [''],
      signatureOption: [''],
      Signature: [''],
      Initials: [''],
      Signature2: [''],
      Initials2: [''],
      Signature3: [''],
      Initials3: [''],
      securityQuestionId1: ['', [Validators.required]],
      answer1: ['', [Validators.required]],
      securityQuestionId2: ['', [Validators.required]],
      answer2: ['', [Validators.required]],
      securityQuestionId3: ['', [Validators.required]],
      answer3: ['', [Validators.required]],
    });
    this.profileForm.controls['securityQuestionId1'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls['securityQuestionId2'].value ||
          val === this.profileForm.controls['securityQuestionId3'].value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls['securityQuestionId1'].patchValue('');
        }
      }
    );
    this.profileForm.controls['securityQuestionId2'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls['securityQuestionId1'].value ||
          val === this.profileForm.controls['securityQuestionId3'].value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls['securityQuestionId2'].patchValue('');
        }
      }
    );
    this.profileForm.controls['securityQuestionId3'].valueChanges.subscribe(
      (val) => {
        if (
          val === this.profileForm.controls['securityQuestionId1'].value ||
          val === this.profileForm.controls['securityQuestionId2'].value
        ) {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            summary: 'Question Already Selected..!',
            detail: 'Please Select Different Security Question',
          });
          this.profileForm.controls['securityQuestionId2'].patchValue('');
        }
      }
    );
  }

  patchForm() {
    this.profileForm.patchValue({
      shortName: this.user.shortName,
      displayName: this.user.displayName,
      email: this.user.email,
      phoneNumber: this.user.phoneNumber,
      signatureOption: this.user.signatureOption,
    });
    if (this.user.securityQuestionId1 != null) {
      const sq1 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId1
      );
      this.profileForm.patchValue({
        securityQuestionId1: sq1,
        answer1: this.user.answer1,
      });
    }
    if (this.user.securityQuestionId2 != null) {
      const sq2 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId2
      );
      this.profileForm.patchValue({
        securityQuestionId2: sq2,
        answer2: this.user.answer2,
      });
    }
    if (this.user.securityQuestionId3 != null) {
      const sq3 = this.securityQuestions.find(
        (x) => x.securityQuestionId === this.user.securityQuestionId3
      );
      this.profileForm.patchValue({
        securityQuestionId3: sq3,
        answer3: this.user.answer3,
      });
    }
  }

  changePassword() {
    this.showChangePassword = true;
  }

  closeChangePassword(saveUser = false) {
    this.showChangePassword = false;
    if (saveUser) {
      this.user.isSignupVerified = true;
      this.user.isProfileVerified = true;
      this.user.userStatusId = 3;
      this.saveUser();
    }
  }

  getData() {
    const tokenUserId = this.encrDecrService.decryptData(
      sessionStorage.getItem('tokenUserId')
    );
    this.userService
      .getByUserId(tokenUserId)
      .pipe(
        mergeMap((user) => {
          this.user = user;
          return this.userService.securityQuestionDropDown();
        })
      )
      .subscribe((res) => {
        this.securityQuestions = res;
        this.patchForm();
      });
  }
}
