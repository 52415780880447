import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncateStringPipe implements PipeTransform {
  transform(target: string, numberOfChars = 8) {
    if (target === undefined || target === null) {
      return target;
    }
    if (numberOfChars < 1 || target.length <= numberOfChars) {
      return target;
    }
    return target.slice(0, numberOfChars - 1) + '...';
  }
}
