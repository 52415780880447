import { Injectable } from '@angular/core';

import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    if (sessionStorage.getItem('currentUser')) {
      return true;
    }
    if (sessionStorage.getItem('lidoAdminType') === 'Admin') {
      return true;
    }
    this.router.navigate(['/signin']);
    return false;
  }
}
