import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-p-message',
  templateUrl: './p-message.component.html',
  styleUrls: ['./p-message.component.scss'],
  providers: [MessageService]
})
export class PrimeMessageComponent implements OnChanges {
  @ViewChild('msg') msg: Message;
  @Input() messages: Message[];
  @Input() delay: number;
  closable = true;

  // If delay is not defined then display a close button

  constructor(private messageService: MessageService) {}

  ngOnChanges() {
    this.closable = (this.delay === undefined);
    // this.messageService.addAll([{severity:'success', summary:'Service Message', detail:'Via MessageService'},
    // {severity:'info', summary:'Info Message', detail:'Via MessageService'}]);
    if (this.delay !== undefined) {
      setTimeout(() => {
        this.messages = [];
      }, this.delay)
    }
  }
}
