<div class="b_container messages">
    <div class="formtitle"> Profile
    </div>
    <div class="pronav">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="grid" *ngIf="displayFirstPage">
                <div class="col-2 prolist">
                    <ul>
                        <li class="proactive">
                            <i class="pi pi-circle-fill" style="font-size: 1.5rem"></i>
                            <div class="protextmar">Personal Info</div>
                        </li>
                        <li>
                            <div class="prodiv"></div>
                        </li>
                        <li class="proinactive cursorp" (click)="switchPage()" *ngIf="profileForm.valid">
                            <div class="pi pi-circle-fill" style="font-size: 1.5rem"></div>
                            <div class="protextmar">Security Info</div>
                        </li>
                        <li class="proinactive cursorp" *ngIf="!profileForm.valid">
                            <div class="pi pi-circle-fill" style="font-size: 1.5rem"></div>
                            <div class="protextmar">Security Info</div>
                        </li>
                    </ul>
                </div>

                <div class="col-4">
                    <div class="flex flex-column gap-1 mb-2">
                        <label for="shortName">Legal Name</label>
                        <div>
                            <input id="shortName" pInputText class="form-control p-inputtext-sm"
                                formControlName="shortName" type="text" />
                            <span class="mandatory"> *</span>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['shortName'].valid && profileForm.controls['shortName'].dirty">
                            Legal Name is Required. Minimum 3 and Maximum 20 Characters Allowed
                        </div>
                    </div>

                    <div class="flex flex-column gap-1 mb-2">

                        <label for="displayName">Display Name</label>
                        <div>
                            <input id="displayName" pInputText class="form-control p-inputtext-sm"
                                pTooltip="Display Name will be used as the salutation in all outgoing emails"
                                tooltipPosition="bottom" type="text" formControlName="displayName" />

                            <span class="mandatory"> *</span>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['displayName'].valid && profileForm.controls['displayName'].dirty">
                            Display Name Minimum 3 and Maximum 70 Characters Allowed
                        </div>
                    </div>

                    <div class="flex flex-column gap-1 mb-2">
                        <p-button type="button" (click)="changePassword()">Change
                            Password</p-button>

                    </div>

                    <div class="flex flex-column gap-1 mb-2">
                        <input pInputText class="form-control p-inputtext-sm" readonly type="email"
                            placeholder="Email Address" formControlName="email" />

                    </div>

                    <div class="flex flex-column gap-1 mb-2 maskinput">
                        <p-inputMask class="p-inputtext-sm" mask="(999) 999-9999" placeholder="(999) 999-9999"
                            ngDefaultControl formControlName="phoneNumber">
                        </p-inputMask>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <p-radioButton formControlName="signatureOption" name="signatureOption" value="1"
                            label="Signature 1" inputId="radioOption1" ngDefaultControl></p-radioButton>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                            Choose at least one signature
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <input pInputText [readonly]="true" value="{{profileForm.controls['displayName'].value}}"
                            class="form-control signftf p-inputtext-sm" formControlName="Signature" type="text" />
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <label class="col-fixed" for="initials">Initials</label>
                        <input pInputText [readonly]="true"
                            value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                            class="form-control signftf p-inputtext-sm" formControlName="Initials" type="text" />
                    </div>

                    <div class="flex flex-column gap-1 mb-2">
                        <p-radioButton formControlName="signatureOption" name="signatureOption" value="2"
                            label="Signature 2" inputId="radioOption2" ngDefaultControl></p-radioButton>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                            Choose at least one signature
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <input pInputText [readonly]="true" value="{{profileForm.controls['displayName'].value}}"
                            class="form-control signfts p-inputtext-sm" formControlName="Signature2" type="text" />
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <label class="col-fixed" for="initials2">Initials</label>
                        <input pInputText [readonly]="true"
                            value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                            class="form-control signfts p-inputtext-sm" formControlName="Initials2" type="text" />
                    </div>

                    <div class="flex flex-column gap-1 mb-2">
                        <p-radioButton formControlName="signatureOption" name="signatureOption" value="3"
                            label="Signature 3" inputId="radioOption3" ngDefaultControl></p-radioButton>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['signatureOption'].valid && profileForm.controls['signatureOption'].dirty">
                            Choose at least one signature
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <input pInputText [readonly]="true" value="{{profileForm.controls['displayName'].value}}"
                            class="form-control signftt p-inputtext-sm" formControlName="Signature3" type="text" />
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <label class="col-fixed" for="initials3">Initials</label>
                        <input pInputText [readonly]="true"
                            value="{{profileForm.controls['displayName'].value | GetfirstLetter}}"
                            class="form-control signftt p-inputtext-sm" formControlName="Initials3" type="text" />
                    </div>

                    <div class="btnnav">
                        <p-button type="button" (click)="switchPage()">Next</p-button>
                        <p-button type="button" severity="secondary" (click)="cancelProfile()">Cancel</p-button>
                    </div>
                </div>

                <div class="col-1 bdr_rt"></div>
                <div class="col-1"></div>

                <div class="col-3">
                    <div class="forminfo">
                        <ul>
                            <li>If you want to change your legal name, display name or password you can change it here.
                                For email address changes please contact your Site Administrator. </li>
                            <li>Legal Name - This should be your legal name that is used for the delegation log and your
                                signature on document approvals. </li>
                            <li>Display Name - This is how you want us to address you throughout LynK and any
                                communications, for example email alerts. </li>
                            <li>Password should be Minimum 6 and Maximum 12 Characters,
                                at least one UPPERCASE letter, one lowercase letter, one number and one special
                                character.</li>
                            <li>(*) Fields are mandatory.</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="grid" *ngIf="!displayFirstPage">
                <div class="col-2 prolist">
                    <ul>
                        <li class="prodone cursorp" (click)="switchPage()">
                            <div class="pi pi-circle-fill" style="font-size: 1.5rem"></div>
                            <div class="protextmar">Personal info</div>
                        </li>
                        <li>
                            <div class="prodiv2"></div>
                        </li>
                        <li class="proactive">
                            <div class="pi pi-circle-fill" style="font-size: 1.5rem"></div>
                            <div class="protextmar">Security info</div>
                        </li>
                    </ul>
                </div>

                <div class="col-4 pgmsgs">
                    <div class="spacer"></div>
                    <p-messages [(value)]="msgs"></p-messages>
                    <div class="flex flex-column gap-1 mb-2 dd95">
                        <div>
                            <p-dropdown [options]="securityQuestions" formControlName="securityQuestionId1"
                                placeholder="Security Question" optionLabel="securityQuestion">
                                <label></label>
                            </p-dropdown>
                            <span class="mandatory"> *</span>
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <div>
                            <input pInputText class="form-control" formControlName="answer1" type="text"
                                placeholder="Answer 1" />
                            <span class="mandatory"> *</span>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['answer1'].valid && profileForm.controls['answer1'].dirty">
                            Answer 1 is Required
                        </div>
                    </div>

                    <div class="flex flex-column gap-1 mb-2 dd95">
                        <div>
                            <p-dropdown [options]="securityQuestions" formControlName="securityQuestionId2"
                                placeholder="Security Question" optionLabel="securityQuestion"></p-dropdown>
                            <span class="mandatory"> *</span>
                        </div>
                    </div>

                    <div class="flex flex-column gap-1 mb-2">
                        <div>
                            <input pInputText class="form-control" type="text" formControlName="answer2"
                                placeholder="Answer 2" />
                            <span class="mandatory"> *</span>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['answer2'].valid && profileForm.controls['answer2'].dirty">
                            Answer 2 is Required
                        </div>
                    </div>

                    <div class="flex flex-column gap-1 mb-2 dd95">
                        <div>
                            <p-dropdown [options]="securityQuestions" formControlName="securityQuestionId3"
                                placeholder="Security Question" optionLabel="securityQuestion"></p-dropdown>
                            <span class="mandatory"> *</span>
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 mb-2">
                        <div>
                            <input pInputText class="form-control p-inputtext-sm" type="text" formControlName="answer3"
                                placeholder="Answer 3" />
                            <span class="mandatory"> *</span>
                        </div>
                        <div class="alert alert-danger"
                            *ngIf="!profileForm.controls['answer3'].valid && profileForm.controls['answer3'].dirty">
                            Answer 3 is Required
                        </div>
                    </div>

                    <div class="btnnav">
                        <p-button type="submit" [disabled]="(!profileForm.valid)                        
                        || (btnCreate==='Processing..')" class="btn btn-primary marright">{{btnCreate}}
                            <i class="fa fa-spinner fa-spin" *ngIf="loadingSub"></i>
                        </p-button>
                        <p-button type="button" (click)="cancelProfile()">Cancel</p-button>
                        <p-button type="button" severity="secondary" (click)="switchPage()">Back</p-button>
                    </div>
                </div>

                <div class="col-1 bdr_rt"></div>
                <div class="col-1"></div>
                <div class="col-3">
                    <div class="forminfo">
                        <ul>
                            <li>These questions will be used to verify your identify and help recover your password if
                                you ever
                                forget it.</li>
                            <li>
                                <span class="mandatory">*</span> fields are mandatory.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<p-sidebar *ngIf="showChangePassword" [(visible)]="showChangePassword" [showCloseIcon]="false" position="right"
    [blockScroll]="true" [dismissible]="false" [baseZIndex]="10000" [closeOnEscape]="false" styleClass="p-sidebar-md">
    <app-change-password [user]="user" [isVerified]="isSignupVerified"
        (closeChangePassword)="closeChangePassword(false)"></app-change-password>
</p-sidebar>