import { FormGroup } from '@angular/forms';

export class RegistrationValidator {
  static validate(registrationFormGroup: FormGroup) {
    const password = registrationFormGroup['controls']['password'].value;
    const repeatPassword =
      registrationFormGroup['controls']['repeatPassword'].value;
    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true,
      };
    }
    return null;
  }

  static pswvalidate(registrationFormGroup: FormGroup) {
    const password = registrationFormGroup['controls']['password'].value;
    const isValid =
      /(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/.test(
        password
      );
    if (isValid === false) {
      return {
        doesChkPassword: true,
      };
    }
    return null;
  }
}
