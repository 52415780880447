import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationValidator } from '../password-equal-validator';
import { User } from 'src/app/users/model/user.model';
import { Message } from 'primeng/api';
import { RequestErrors, password_generator } from 'src/app/common/utils';
import { ForgotPasswordService } from 'src/app/auth/forgotpassword.service';
import { ResetPassword } from 'src/app/auth/model/resetpassword.model';
import { EncrDecrService } from 'src/app/common/encr-decr.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() user: User;
  @Input() isVerified = false;
  @Output() closeChangePassword = new EventEmitter<boolean>();
  form: FormGroup;
  msgs: Message[] = [];
  pageTitle = 'Change Password';
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private encrDecrService: EncrDecrService,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  onGenerate() {
    const newPassword = password_generator(8);
    this.form.controls['password'].setValue(newPassword);
    this.form.controls['repeatPassword'].reset();
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
            ),
          ],
        ],
        repeatPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
            ),
          ],
        ],
      },
      {
        validator: RegistrationValidator.validate.bind(this),
        validators: RegistrationValidator.pswvalidate.bind(this),
      }
    );
  }

  closeForm(saveUser = false) {
    if (this.isVerified) {
      this.closeChangePassword.emit(saveUser);
    }
  }

  onSubmit() {
    const resetPassword: ResetPassword = {
      currentPassword: this.user.password,
      changedPassword: this.form.controls['password'].value,
      sendEmail: false,
      userId: this.user.userId.toString(),
      requestType: 1,
    };
    this.forgotPasswordService.resetPassword(resetPassword).subscribe(
      (messageResponse) => {
        const apires = messageResponse;
        if (apires.success === true) {
          const sgv = this.encrDecrService.encryptData(true);
          sessionStorage.setItem('sgv', sgv);
          sessionStorage.setItem('isPasswordChanged', 'true');
          this.isVerified = true;
          this.closeForm(true);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: 'error',
            detail: apires.errorMessages[0],
          });
        }
      },
      (error) => {
        this.msgs = RequestErrors(error).map((x) => {
          return { severity: 'warn', detail: x };
        });
      }
    );
  }
}
