import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../common/apiresponse';
import { Observable } from 'rxjs';

import { User } from '../model/user.model';
import { UserRole } from '../model/userrole.model';
import { UserType } from '../model/usertype.model';
import { SecurityQuestion } from '../model/securityquestion.model';
import { UserPermissionDTO } from '../model/userpermission.dto';
import { UserSite } from '../model/user-site.model';
import { AuthUser } from '../model/authuser.model';
import { SecurityQuestionDTO } from '../model/securityquestionDto.model';
import { UserSettings } from '../model/user-settings.model';
import { UserSettingsLibrary } from '../model/user-settings-library.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.apiUrl + '/';
  headers = new HttpHeaders().set('content-type', 'application/json');

  constructor(private http: HttpClient) {}

  // Get all users on a site
  getUsersForSite(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/user/SiteId/' + siteId);
  }
  // Get all db users who aren't on siteId site for add to site
  getUsersForAddToSite(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.baseUrl + 'api/user/UsersForAddToSite/SiteId/' + siteId
    );
  }

  // For editing site to allow user to change the site admin
  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'api/user');
  }

  // Used by addtrial to select the pi
  getUserDetailsforTrials(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.baseUrl + 'api/user/GetUserDetailsForTrials/SiteId/' + siteId
    );
  }

  // Used by add-trialuser-list to get all site users who aren't already
  // part of trialid trial
  getNonPIUserDetailsforTrials(
    siteId: number,
    trialId: number
  ): Observable<User[]> {
    return this.http.get<User[]>(
      this.baseUrl +
        'api/user/GetNonPIUserDetailsForTrials/SiteId/' +
        siteId +
        '/TrialId/' +
        trialId
    );
  }

  getWorkFlowRoutingUserListForTrials(
    siteId: number,
    trialId: number
  ): Observable<User[]> {
    return this.http.get<User[]>(
      this.baseUrl +
        'api/user/GetWorkFlowRoutingUserListForTrials/SiteId/' +
        siteId +
        '/TrialId/' +
        trialId
    );
  }

  // This one is for when the site admin sends a password reset request
  resetPassword(resetPassword: User) {
    return this.http.patch<ApiResponse>(
      this.baseUrl + 'api/user/ResetPassword',
      resetPassword,
      { headers: this.headers }
    );
  }

  // Used by edit profile
  getByUserId(userId: number): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/user/' + userId);
  }

  deactivateUser(userId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.baseUrl + 'api/user/' + userId);
  }

  // Used to save changes to user
  editUser(userId: number, edituser: User) {
    return this.http.put<ApiResponse>(
      `${this.baseUrl}api/user/${userId}`,
      edituser,
      { headers: this.headers }
    );
  }

  editUserBlock(userId: number, block: boolean) {
    const blockValue = block === true ? 1 : 0;
    return this.http.put<ApiResponse>(
      `${this.baseUrl}api/user/ToggleBlock/${userId}/${blockValue}`,
      { headers: this.headers }
    );
  }

  // Used to create a new user
  createUser(newuser: User) {
    return this.http.post<ApiResponse>(this.baseUrl + 'api/user', newuser, {
      headers: this.headers,
    });
  }

  getSecurityQuestion(): Observable<SecurityQuestion[]> {
    return this.http.get<SecurityQuestion[]>(
      this.baseUrl + 'api/securityquestion'
    );
  }
  securityQuestionDropDown(): Observable<SecurityQuestionDTO[]> {
    return this.http.get<SecurityQuestionDTO[]>(
      this.baseUrl + 'api/securityquestion/SecurityQuestionDropDown'
    );
  }

  updateUserType(user: UserPermissionDTO) {
    return this.http.put<ApiResponse>(
      this.baseUrl + 'api/userpermission',
      user,
      { headers: this.headers }
    );
  }

  // Called whenever a user changes site or trial to get
  // their current credentials, user type, and role
  regenerateTokenOnSiteChange(siteChange: UserSite) {
    return this.http.post<AuthUser>(
      this.baseUrl + 'api/auth/RegenerateTokenOnSiteChange',
      siteChange,
      { headers: this.headers }
    );
  }

  // Used to get the user's settings for a site and trial
  getUserSettings(
    siteId: number,
    settingsLibraryId: number,
    trialId: number
  ): Observable<UserSettings[]> {
    return this.http.get<UserSettings[]>(
      `${
        this.baseUrl
      }api/UserSettings/GetUserSettings/SiteId/${siteId}/SettingsLibraryId/${settingsLibraryId}/TrialId/${
        trialId || '0'
      }`
    );
  }

  // Used to get all settings that can be set
  getUserSettingsLibrary(siteId: number): Observable<UserSettingsLibrary[]> {
    return this.http.get<UserSettingsLibrary[]>(
      `${this.baseUrl}api/UserSettings/GetUserSettingsLibrary/SiteId/${siteId}`
    );
  }

  // Used to save users settings
  saveUserPreferences(udt: UserSettings[]): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.baseUrl}api/UserSettings/SaveUserSettingsPreferences`,
      udt
    );
  }

  // Used to get all user types
  getUserTypes(): Observable<UserType[]> {
    return this.http.get<UserType[]>(this.baseUrl + 'api/usertype');
  }

  updateUserTypeItem(userType: UserType): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.baseUrl + 'api/usertype', userType);
  }

  createUserTypeItem(userType: UserType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'api/usertype', userType);
  }

  // Used to get all user roles
  getUserRoles(filterDeleted = false): Observable<UserRole[]> {
    const parm = filterDeleted ? 1 : 0;
    return this.http.get<UserRole[]>(
      this.baseUrl + `api/UserRole/UserRoleDropdown/${parm}`
    );
  }
}
