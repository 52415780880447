import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './profile.component';
import { GetfirstLetterPipe } from './profile.component.pipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, SharedModule, HttpClientModule],
  declarations: [ProfileComponent, ChangePasswordComponent, GetfirstLetterPipe],
  exports: [ProfileComponent, ChangePasswordComponent, GetfirstLetterPipe],
})
export class ProfileModule {}
