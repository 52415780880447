import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApiResponse } from '../common/apiresponse';
import { ForgotPasswordSecqtn } from './model/forgot-password-secqtn.model';
import { ForgotPassword } from './model/forgotpassword.model';
import { ResetPassword } from './model/resetpassword.model';
import { ApiResponseResetPassword } from './model/apiresponserestpwd.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(private http: HttpClient) {}
  baseUrl = environment.apiUrl + '/';

  verifyEmailAddress(forgotPassword: ForgotPassword) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<ApiResponse>(
      this.baseUrl + 'api/forgotpassword',
      forgotPassword,
      { headers }
    );
  }

  verifyEmailAddressSQ(forgotPassword: ForgotPassword) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<ApiResponseResetPassword>(
      this.baseUrl + 'api/forgotpassword',
      forgotPassword,
      { headers }
    );
  }

  verifyOTP(forgotPassword: ForgotPassword) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<ApiResponse>(
      this.baseUrl + 'api/forgotpassword/validateotp',
      forgotPassword,
      { headers }
    );
  }

  verifySecQtnsAns(forgotPasswordSecqtn: ForgotPasswordSecqtn) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<ApiResponseResetPassword>(
      this.baseUrl + 'api/forgotpassword/validatesqa',
      forgotPasswordSecqtn,
      { headers }
    );
  }

  // This one is for when the user changes their password
  resetPassword(resetPassword: ResetPassword) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<ApiResponse>(
      this.baseUrl + 'api/forgotpassworddetails',
      resetPassword,
      { headers }
    );
  }
}
