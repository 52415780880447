import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { IdleService } from './common/idle.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  envString = environment.production ? 'Production' : 'Development';
  title = `Lido-Admin for ${this.envString}`;
  isLoggedIn = false;

  items = [
    {
      label: 'Users',
      icon: 'pi pi-fw pi-user',
      items: [
        {
          label: 'User List',
          routerLink: '/users',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'User Types',
          routerLink: '/users/user-types',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'User Roles',
          routerLink: '/users/user-roles',
          icon: 'pi pi-fw pi-user',
        },
      ],
    },
    {
      label: 'Sites',
      icon: 'pi pi-fw pi-pencil',
      items: [
        {
          label: 'Site List',
          icon: 'pi pi-fw pi-user',
          routerLink: '/sites/site-list',
        },
      ],
    },
    {
      label: 'Trials',
      icon: 'pi pi-fw pi-pencil',
      items: [
        {
          label: 'Trial List',
          icon: 'pi pi-fw pi-user',
          routerLink: '/trials',
        },
        {
          label: 'Sponsor List',
          icon: 'pi pi-fw pi-user',
          routerLink: '/sites/sponsor-list',
        },
      ],
    },
    {
      label: 'Vendors',
      icon: 'pi pi-fw pi-user',
      items: [
        {
          label: 'Vendor List',
          routerLink: '/vendors',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'Vendor Users',
          routerLink: '/vendors/vendor-user-list',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'Vendor Categories',
          routerLink: '/vendors/vendor-categories',
          icon: 'pi pi-fw pi-user',
        },
      ],
    },
    {
      label: 'Faq Library',
      icon: 'pi pi-fw pi-calendar',
      items: [
        {
          label: 'Faq List',
          routerLink: '/faqs',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'Help Request List',
          routerLink: '/faqs/help-request-list',
          icon: 'pi pi-fw pi-user',
        },
      ],
    },
    {
      label: 'Events',
      icon: 'pi pi-fw pi-calendar',
    },
  ];

  constructor(
    private idleService: IdleService,
    private router: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {
    console.log(
      'environment.production is ',
      environment.production,
      ' apiUrl is ',
      environment.apiUrl
    );
    if (sessionStorage.getItem('currentUser')) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    this.authService.loginState$.subscribe((val) => {
      this.isLoggedIn = val;
    });
    this.initialIdleSettings();
  }

  initialIdleSettings() {
    const idleTimeoutInSeconds = 30 * 60;
    this.idleService
      .startWatching(idleTimeoutInSeconds)
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          this.authService.logout();
          this.router.navigate(['/signin'], {
            skipLocationChange: true,
          });
        }
      });
  }
}
