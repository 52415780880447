import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, Subject } from 'rxjs';

import { AuthUser } from '../users/model/authuser.model';
import { EncrDecrService } from '../common/encr-decr.service';
import { environment } from 'src/environments/environment';
export interface DogResponse {
  message: string;
  status: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string = environment.oktaConfig.redirectUri;
  private loginStateChanged = new Subject<boolean>();
  loginState$ = this.loginStateChanged.asObservable();
  baseUrl = environment.apiUrl + '/';

  oktaAuth = new OktaAuth({
    issuer: environment.oktaConfig.issuer,
    clientId: environment.oktaConfig.clientId,
    redirectUri: environment.oktaConfig.redirectUri,
  });

  constructor(
    private http: HttpClient,
    private encrdcrService: EncrDecrService
  ) {}

  login(username: string, password: string): Observable<AuthUser> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<AuthUser>(
      this.baseUrl + 'api/auth/requesttokenPsso',
      JSON.stringify({ username: username, password: password }),
      { headers }
    );
  }

  async logout(): Promise<void> {
    sessionStorage.clear();
    this.isLoggedIn = false;
    this.loginStateChanged.next(false);
    await this.oktaAuth.signOut();
  }

  setAuthUserSessionStorage(
    authUser: AuthUser,
    oktaUserId?: string,
    rememberMe = false
  ) {
    this.isLoggedIn = true;
    this.loginStateChanged.next(true);
    sessionStorage.clear();
    sessionStorage.setItem(
      'currentUser',
      JSON.stringify(authUser.securityToken)
    );
    sessionStorage.setItem('lidoUserType', authUser.lidoUserType);
    if (authUser.displayName) {
      sessionStorage.setItem('displayName', authUser.displayName);
    }
    sessionStorage.setItem(
      'isSiteAdmin',
      authUser.isSiteAdmin ? 'true' : 'false'
    );
    sessionStorage.setItem(
      'extUser',
      authUser.isExternalUser ? 'true' : 'false'
    );

    if (authUser.userTypeId) {
      sessionStorage.setItem('userTypeId', authUser.userTypeId.toString());
    }
    sessionStorage.setItem(
      'sgv',
      this.encrdcrService.encryptData(authUser.isSignupVerified)
    );
    sessionStorage.setItem(
      'tokenUserId',
      this.encrdcrService.encryptData(authUser.userId)
    );

    // If this isn't passed it is just a site change
    if (oktaUserId !== undefined) {
      sessionStorage.setItem('SsoUserId', oktaUserId);
    }
    sessionStorage.setItem('isalertload', '0');
    if (rememberMe && rememberMe === true && authUser.userName) {
      localStorage.setItem('username', authUser.userName);
    }
    sessionStorage.setItem('isResonforVisit', 'true');
    if (authUser.isSignupVerified === true) {
      sessionStorage.setItem('isPasswordChanged', 'true');
    } else {
      sessionStorage.setItem('isPasswordChanged', 'false');
    }
  }
}
