// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  apiUrl: 'https://lynkdev.azurewebsites.net/services',
  redirectUrl: 'https://lynkdev.azurewebsites.net',
  imageContainer: 'https://lynkdevimages.blob.core.windows.net',
  oktaConfig: {
    issuer: 'https://lido-io.okta.com',
    clientId: '0oadrigst4oULP5hF4x7',
    redirectUri: 'https://lynkdev.azurewebsites.net',
  },
};
