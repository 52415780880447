import { environment } from 'src/environments/environment';
export class AppConstants {
  // Error Messages
  public static readonly APIGenericError = 'Bad request';
  public static readonly APIInternalServerError = 'Internal server error';
  public static readonly APIRouteError = 'Bad API route request';
  public static readonly APIAuthError = 'Authentication Error on request';

  // All Dataview ,Data Table --Empty Message
  public static readonly emptyRecordMsg = 'No Record Found';
  public static readonly vendorFlowView = 'grid';
  public static readonly documentFlowView = 'list';
  public static readonly helpView = 'list';

  // home,vendor in home and Vendor Library
  public static readonly dataViewRecsPerPage = 6;
  public static readonly dataViewPagerLinks = 10;

  // Trial desboard,main user list
  public static readonly dataListRecsPerPage = 6;
  public static readonly dataListPagerLinks = 6;

  // Site doc files,operation binder,regular binder and subject document
  public static readonly docListRecsPerPage = 12;
  public static readonly docListPagerLinks = 10;

  // Home page of Site doc ,operation binder,regular binder and subject document
  public static readonly folderListRecsPerPage = 12;
  public static readonly folderListGridPerPage = 6;
  public static readonly folderListPagerLinks = 10;

  // Trial user edit,Proxy Pi and Pi Name,WorkFlow and AdverseEvent user List
  public static readonly trialUserListRecsPerPage = 15;
  public static readonly trialUserListPagerLinks = 15;

  // Cancellable & Actionable Alert
  public static readonly alertsListRecsPerPage = 10;
  public static readonly alertListPagerLinks = 10;

  // public static get emptyRecordMsg(): string { return "No Record Found"; }

  // --------------------------SSO Value On/Off------------------
  public static readonly Psso = false;

  public static readonly autoCloseTimer = 2000;

  public static readonly maxLogoSize = 102400;
  public static readonly maxDocumentSize = 10485760;

  // Binder folder names
  public static readonly OperationsBinderBlobName = 'operationbinderdocuments';
  public static readonly RegulatoryBinderBlobName = 'regulatorybinderdocuments';
  public static readonly SubjectBinderBlobName = 'subjectdocuments';
  public static readonly SiteDocumentBlobName = 'sitedocuments';

  // Blob storage defaults
  public static readonly defaultSiteLogo = `${environment.imageContainer}/images/sitedefaultimage.png`;
  public static readonly defaultVendorLogo = `${environment.imageContainer}/images/vendordefaultimage.jpg`;
  public static readonly defaultSponsorLogo = `${environment.imageContainer}/images/sponsordefaultimage.png`;

  // Document Upload urls
  public static readonly OperationBinderDocUploadUrl = `${environment.apiUrl}/api/operationbinderdocs/UploadFile`;
  public static readonly OperationBinderDocDownloadUrl = `${environment.apiUrl}/api/operationbinderdocs/DownloadFile`;

  // Copy and route urls
  public static readonly OperationBinderDocCopyAndRouteUrl = `${environment.apiUrl}/api/operationbinderdocs/CopyAndRoute`;
  public static readonly RegulatoryBinderDocCopyAndRouteUrl = `${environment.apiUrl}/api/regulatorybinderdocs/CopyAndRoute`;
  public static readonly SiteDocCopyAndRouteUrl = `${environment.apiUrl}/api/siteDocument/CopyAndRoute`;
  public static readonly SubjectDocCopyAndRouteUrl = `${environment.apiUrl}/api/subjectdocument/CopyAndRoute`;

  // Get Folderlist urls
  public static readonly OperationBinderDocFolderListUrl = `${environment.apiUrl}/api/operationbinderfolder/operationBinderfoldertreelist/sid/`;
  public static readonly RegulatoryBinderDocFolderListUrl = `${environment.apiUrl}/api/regulatorybinderfolder/regulatorybinderfoldertreelist/sid/`;
  public static readonly SiteDocFolderListUrl = `${environment.apiUrl}/api/siteDocument/getSiteTreeList/sid/`;
  public static readonly SubjectDocFolderListUrl = `${environment.apiUrl}/api/SubjectRecordsFolder/SubjectRecordsFolderTreeList/sid/`;

  // Docflowlog urls
  public static readonly OperationBinderDocFlowUrl = `${environment.apiUrl}/api/OperationBinderFolder/MyOperBinderUploadedFileLogBySiteId/sid/`;
  public static readonly SiteDocumentDocFlowUrl = `${environment.apiUrl}/api/SiteDocumentFolder/MySiteUploadedFileLogBySiteId/sid/`;
  public static readonly RegulatoryBinderDocFlowUrl = `${environment.apiUrl}/api/RegulatoryBinderFolder/MyRegBinderUploadedFileLogBySiteId/sid/`;
  public static readonly SubjectRecordDocFlowUrl = `${environment.apiUrl}/api/SubjectRecordsFolder/MySubRecordUploadedFileLogBySiteId/sid/`;
}

export const userTypes = {
  1: 'SiteAdmin',
  2: 'SiteUser',
  3: 'ExternalUser',
};

export const trialRoles = {
  1: 'PrimaryInvestigator',
  2: 'SubInvestagor',
  3: 'ExternalUser',
};
