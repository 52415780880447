<div class="b_container messages">
    <div class="flex flex-row justify-content-between align-items-center">
        <div class="formtitle">
            <i class="pi pi-user" style="font-size: 1.5rem"></i> {{pageTitle}}
        </div>
        <div *ngIf="loading!==true" class="closesidebar" (click)="closeForm()">X</div>
    </div>
    <div class="rtcontainer userform">
        <p-messages [(value)]="msgs"></p-messages>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="flex flex-column">
                <div class="flex flex-column gap-1 mb-2">
                    <label>New Password</label>

                    <div class="col-sm-8">
                        <p-password class="form-control" placeholder="Password" [toggleMask]="true" [feedback]="false"
                            formControlName="password"></p-password>
                        <span class="mandatory"> *</span>
                    </div>

                    <div class="alert alert-danger"
                        *ngIf="!form.controls['password'].valid && form.controls['password'].dirty">
                        Password should be Minimum 6 and Maximum 12 Characters, at least one UPPERCASE letter,
                        one lowercase letter, one number and one special character
                    </div>
                </div>
                <div class="flex flex-column gap-1 mb-2">
                    <label>Confirm Password</label>

                    <div class="col-sm-8">
                        <p-password class="form-control" placeholder="Repeat Password" [feedback]="false"
                            [toggleMask]="true" formControlName="repeatPassword"></p-password>
                        <span class="mandatory"> *</span>
                    </div>

                    <div class="alert alert-danger" *ngIf="(form.controls['repeatPassword'].value!=='' && form.controls['password'].value!==form.controls['repeatPassword'].value) 
                           && (form.dirty) && (form.touched)">
                        Password and Confirm Password must match
                    </div>
                </div>

                <div class="btnnav">
                    <p-button type="button" (click)="onGenerate()">Generate
                        password</p-button>
                    <p-button type="submit" [disabled]="!form.valid" label="Submit"></p-button>
                    <p-button type="button" severity="secondary" [disabled]="!isVerified"
                        (click)="closeForm()">Cancel</p-button>
                </div>
            </div>
        </form>
    </div>
</div>