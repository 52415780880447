import { HttpErrorResponse } from '@angular/common/http';

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function RequestErrors(error: HttpErrorResponse): string[] {
  if (error.error !== undefined) {
    if (Array.isArray(error.error)) {
      return error.error;
    } else {
      return [error.error];
    }
  }
  return [error.message];
}

// From https://stackoverflow.com/questions/1497481/javascript-password-generator?rq=3
export function password_generator(passwordLength = 8) {
  const validChars = 'abcdefghijklmnopqrstuvwxyz'; //to upper
  const validNumeric = '0123456789';
  const validSpecialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let password = '';
  let currentCharacter = '';

  while (password.length < passwordLength) {
    const entity1 = Math.ceil(
      validChars.length * Math.random() * Math.random()
    );
    const entity2 = Math.ceil(
      validNumeric.length * Math.random() * Math.random()
    );
    const entity3 = Math.ceil(
      validSpecialChars.length * Math.random() * Math.random()
    );
    let hold = validChars.charAt(entity1);
    hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
    currentCharacter += hold;
    currentCharacter += validNumeric.charAt(entity2);
    currentCharacter += validSpecialChars.charAt(entity3);
    password = currentCharacter;
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  return password;
}
