<div class="container">
    <div *ngIf="isLoggedIn" class="header-row">
        <app-header>
        </app-header>
    </div>
    <div class="page">
        <div *ngIf="isLoggedIn" class="side-bar">
            <p-panelMenu [model]="items" [multiple]="true"></p-panelMenu>
        </div>
        <div [ngClass]="isLoggedIn ? 'main-content' : 'main-content-login'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>