import { Pipe, PipeTransform } from '@angular/core';

// ghetto style workaround for cache busting after image upload
@Pipe({ name: 'imgurl' })
export class ImageCacheBustPipe implements PipeTransform {
  transform(target: string) {
    if (target === undefined || target === null) {
      return target;
    }

    const timeStamp = new Date().getTime();
    return target + '?' + timeStamp.toString();
  }
}
