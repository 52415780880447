import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeModule } from 'primeng/tree';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ListboxModule } from 'primeng/listbox';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccordionModule } from 'primeng/accordion';
import { StepsModule } from 'primeng/steps';
import { MultiSelectModule } from 'primeng/multiselect';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImageCacheBustPipe } from './pipes/logocache.pipe';
import { HttpClientModule } from '@angular/common/http';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { PrimeMessageComponent } from './message/p-message.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataViewModule,
    ButtonModule,
    TooltipModule,
    OverlayPanelModule,
    DialogModule,
    InputTextModule,
    PanelModule,
    PanelMenuModule,
    DropdownModule,
    CalendarModule,
    SidebarModule,
    RadioButtonModule,
    CheckboxModule,
    TreeModule,
    TableModule,
    InputSwitchModule,
    ToggleButtonModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    ListboxModule,
    InputMaskModule,
    ToastModule,
    PasswordModule,
    AutoCompleteModule,
    SplitButtonModule,
    MenuModule,
    ContextMenuModule,
    ConfirmDialogModule,
    FieldsetModule,
    DynamicDialogModule,
    TabViewModule,
    ScrollPanelModule,
    AccordionModule,
    StepsModule,
    MultiSelectModule,
  ],
  declarations: [PrimeMessageComponent, ImageCacheBustPipe, TruncateStringPipe],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataViewModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    PanelModule,
    TooltipModule,
    OverlayPanelModule,
    DropdownModule,
    CalendarModule,
    SidebarModule,
    TableModule,
    PanelMenuModule,
    RadioButtonModule,
    CheckboxModule,
    TreeModule,
    InputSwitchModule,
    ToggleButtonModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    ListboxModule,
    InputMaskModule,
    ToastModule,
    PasswordModule,
    AutoCompleteModule,
    SplitButtonModule,
    ContextMenuModule,
    MenuModule,
    ConfirmDialogModule,
    FieldsetModule,
    DynamicDialogModule,
    TabViewModule,
    CheckboxModule,
    ScrollPanelModule,
    AccordionModule,
    StepsModule,
    MultiSelectModule,
    PrimeMessageComponent,
    ImageCacheBustPipe,
    TruncateStringPipe,
  ],
  providers: [],
})
export class SharedModule {}
